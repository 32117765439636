import PropTypes from 'prop-types';
import {forwardRef} from 'react';
// @mui
import {Box} from '@mui/material';
import {HEADER_DESKTOP_HEIGHT, HEADER_MOBILE_HEIGHT} from "../../config";
import ScrollToTop from "../utils/scroll-to-top";

// ----------------------------------------------------------------------

const Page = forwardRef(({children, disableMargins = false, ...other}, ref) => (
  <>
    <ScrollToTop/>
    <Box ref={ref} {...other} sx={{mt: disableMargins ? {}: {xs: HEADER_MOBILE_HEIGHT / 8, md: HEADER_DESKTOP_HEIGHT / 8} }}>
      {children}
    </Box>
  </>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  disableMargins: PropTypes.bool
};

export default Page;
